@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --toastify-color-success: #85dbdd;
}

.background-image {
  background-image: url('./images/mediclaudo-auth-7.svg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
